













import { Component, Vue } from "vue-property-decorator";

import App from "@/app/modules/_global/classes/App";

@Component
export default class AppLanguageComponent extends Vue {
  get appData() {
    return this.$store.getters["app/data"];
  }

  get languages() {
    return this.appData.languages;
  }

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  set appLanguage(locale) {
    // TODO: Refactor the 'changeLanguage' functionality
    this.$store.dispatch("profile/updateAppLanguageId", { user_app_locale: locale }).then(() => {
      this.$store.dispatch("app/changeLanguage", locale).then(({ data }) => {
        App.init({ auth: true });
        App.setLocale(locale, data);
      });
    });
  }
}
